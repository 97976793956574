<template>
  <div class="camInfo">
    <mainTitle :title="title">
      <template #right>
        <el-button-group v-if="infoFormData.isEdit">
          <el-button size="mini" @click="saveInfoForm" type="primary"
            >保存
          </el-button>
          <el-button
            style="margin-left: 20px"
            @click="cancelEdit"
            size="mini"
            type="primary"
            >取消
          </el-button>
        </el-button-group>
        <el-button
          icon="el-icon-edit"
          :disabled="exitBtn"
          v-else
          @click="editInfo"
          type="primary"
          size="mini"
          >修改
        </el-button>
      </template>
    </mainTitle>
    <div class="mainInfo">
      <el-form class="infoForm" :model="infoFormData">
        <div class="wrap">
          <el-row>
            <el-col>
              <el-form-item label="活动品牌" label-width="100px" prop="brand">
                <el-select
                  v-if="infoFormData.isEdit"
                  v-model="infoFormData.brand"
                  placeholder="请选择品牌"
                >
                  <el-option
                    v-for="item in brandList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
                <span v-else>{{ infoFormData.brand }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                label="活动名称"
                label-width="100px"
                prop="campaignName"
              >
                <span>{{ infoFormData.campaignName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                label="活动时间"
                label-width="100px"
                prop="duration"
              >
                <el-date-picker
                  v-if="infoFormData.isEdit"
                  v-model="infoFormData.duration"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  value-format="YYYY-MM-DD"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :shortcuts="shortcuts"
                >
                </el-date-picker>
                <span v-else>
                  {{
                    // typeof infoFormData.duration === 'string' ? `${infoFormData.duration[0]} 至 ${infoFormData.duration[1]}`
                    setFormDuration(infoFormData.duration)
                  }}
                </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                label="活动类型"
                label-width="100px"
                prop="campaignType"
              >
                <el-input
                  v-if="infoFormData.isEdit"
                  v-model="infoFormData.campaignType"
                >
                </el-input>
                <span v-else>{{ infoFormData.campaignType }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="wrap">
          <el-row>
            <el-col style="max-height: 98px">
              <el-form-item
                label="活动说明"
                label-width="100px"
                prop="campaignDesc"
              >
                <el-input
                  v-if="infoFormData.isEdit"
                  v-model="infoFormData.campaignDesc"
                  resize="none"
                  style="width: 90%"
                ></el-input>
                <span v-else class="desc">{{ infoFormData.campaignDesc }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="活动预算" label-width="100px" prop="cost">
                <el-input
                  v-if="infoFormData.isEdit"
                  :maxLength="9"
                  v-model="infoFormData.cost"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                  style="width: 90%"
                >
                </el-input>
                <span v-else>{{
                  infoFormData.cost === 0
                    ? ""
                    : `¥ ${sliceDecimal(infoFormData.cost - 0)}`
                }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="品线" label-width="100px" prop="brandLine">
                <el-select
                  v-if="infoFormData.isEdit"
                  v-model="infoFormData.brandLine"
                  allow-create
                  filterable
                  placeholder="请选择品线"
                >
                  <el-option
                    v-for="item in brandLineList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span v-else>{{ infoFormData.brandLine }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="wrap">
          <el-row>
            <el-col>
              <el-form-item label="相关文档" label-width="100px">
                <div class="imgBox">
                  <template v-if="infoFormData.isEdit">
                    <el-upload
                      class="upload-demo"
                      ref="campaignUpload"
                      :action="campaignAction"
                      :headers="{ token }"
                      :limit="limit"
                      :before-upload="fileBeforeUpload"
                      :on-success="campaignFileOnSuccess"
                      :on-error="campaignFileOnError"
                      :on-remove="fileListRemove"
                    >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="上传文档"
                        placement="top"
                      >
                        <svg-icon
                          class-name="upload"
                          class="svgBox upload"
                        ></svg-icon>
                      </el-tooltip>
                    </el-upload>
                  </template>
                  <template v-else>
                    <el-tooltip
                      v-if="infoFormData.docDir !== ''"
                      class="item"
                      effect="dark"
                      :content="
                        infoFormData.docDir === '' ? '未上传文档' : '下载文档'
                      "
                      placement="top"
                    >
                      <svg-icon
                        @click="downLoadCampaignFile"
                        class-name="download"
                        class="svgBox"
                      ></svg-icon>
                    </el-tooltip>
                    <span v-else>无</span>
                  </template>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="供应商" label-width="100px" prop="agency">
                <el-select
                  v-if="infoFormData.isEdit"
                  multiple
                  collapse-tags
                  v-model="infoFormData.agency"
                  placeholder="请选择供应商"
                >
                  <el-option
                    v-for="item in agencyList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span v-else>{{ infoFormData.agency.join(",") }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="产品" label-width="100px" prop="product">
                <el-select
                  v-if="infoFormData.isEdit"
                  v-model="infoFormData.product"
                  filterable
                  allow-create
                  placeholder="请选择产品"
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in productList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span v-else>{{ infoFormData.product.join(",") }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <el-tabs
      @tab-click="tabClick"
      type="border-card"
      style="position: relative"
    >
      <el-button
        @click="intoCampaignReport"
        size="mini"
        class="campaignReportStyle"
      >
        <svg-icon class-name="report" class="reportClass" />
        活动报告
      </el-button>
      <el-tab-pane lazy>
        <template #label>
          <p>
            <svg-icon
              :class="{ isClickColor: iskolDetailsClick }"
              class-name="kolDetails"
              class="kolDetails"
            ></svg-icon>
            KOL详情
          </p>
        </template>
        <div>
          <div class="campBoard">
            <mainTitle :isSecondColor="isSecondColor" :title="campBoardTitle" />
            <el-form class="campBrandFormBox">
              <el-row>
                <el-col :span="14">
                  <el-form-item label="平台" label-width="100px">
                    <el-radio-group v-model="kolDetailPlatform" size="mini">
                      <el-radio-button label="all">全平台</el-radio-button>
                      <el-radio-button
                        v-for="item in sites"
                        :key="item.value"
                        :label="item.value"
                        >{{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="目标人群" label-width="100px">
                    <el-select v-model="detailCrowd">
                      <el-option
                        v-for="item in fincrowdList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div v-loading="detailBoxLoading">
              <div class="littleBox">
                <showCard
                  :info="{ key: '总价', num: `${dataObj.totalCost}` }"
                  icon="price"
                ></showCard>
                <showCard
                  :info="{ key: '预计曝光数', num: dataObj.impression }"
                  icon="puguang"
                ></showCard>
                <showCard
                  :info="{ key: '预计互动数', num: dataObj.engagement }"
                  icon="hudong"
                ></showCard>
                <showCard
                  :showTip="true"
                  :info="{ key: '互动率', num: dataObj.engagementRate }"
                  icon="hudongl"
                ></showCard>
                <showCard
                  :showTip="true"
                  :info="{ key: 'CPE', num: dataObj.cpe }"
                  icon="cpe1"
                ></showCard>
                <showCard
                  :showTip="true"
                  :info="{ key: 'CPUV', num: dataObj.cpi }"
                  icon="cpi1"
                ></showCard>
                <showCard
                  :showTip="true"
                  :info="{ key: 'CPTA', num: dataObj.cpta }"
                  icon="cpuv1"
                ></showCard>
              </div>
            </div>
          </div>
          <div>
            <mainTitle :isSecondColor="isSecondColor" :title="kolDetailTitle" />
            <div style="margin-top: 20px" class="kolPriceDetailHead">
              <el-input
                style="width: 400px"
                placeholder="请输入单个kol名称或者kol id"
                v-model="searchKeyNoAgency"
                @input="campaignSearchNoAgency"
                suffix-icon="el-icon-search"
              >
                <template #prepend>
                  <el-select
                    style="width: 108px"
                    v-model="kolDetailPlatform"
                    placeholder="请选择平台"
                  >
                    <el-option label="全部平台" value="all"></el-option>
                    <el-option
                      v-for="item in sites"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </template>
              </el-input>
              <!-- <div class="choose" @click="filterHandle">
                <svg-icon class-name="choose" class="chooseClass" />
                筛选
              </div> -->
              <div class="btnGroup">
                <el-select
                  size="mini"
                  style="margin-right: 10px"
                  v-model="detailCrowd"
                >
                  <el-option
                    v-for="item in fincrowdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-button
                  icon="el-icon-plus"
                  :disabled="addMoreBtn"
                  type="primary"
                  @click="additionalKol"
                  size="mini"
                >
                  追加KOL
                </el-button>
                <el-button
                  @click="downloadCampaignExcelNoAgency"
                  :loading="tableBtnNoAgency"
                  type="primary"
                  size="mini"
                >
                  <svg-icon
                    class-name="download"
                    class="downloadStyle"
                  ></svg-icon>
                  导出报表
                </el-button>
              </div>
            </div>
            <filter-tag
              v-show="tagshow"
              v-model="resetFlag"
              @change="newSearch"
              @initData="tags = $event"
            />
            <el-table
              ref="multipleTable"
              v-loading="noAgencyloading"
              :data="noAgencyTableData[kolDetailPlatform]"
              max-height="650"
              style="margin-top: 10px"
              :header-cell-style="headerStyle"
              :cell-style="cellStyle"
            >
              <el-table-column
                prop="kolName"
                label="KOL名称"
                width="130"
                :show-overflow-tooltip="true"
                align="left"
              >
                <template #default="{ row }">
                  <div class="nameStyle">
                    <img :src="row.logoUrl" alt="" />
                    <span>{{ row.kolName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="platform"
                label="平台"
                width="80"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="pKolid"
                label="KOL ID"
                width="120"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="kolCategory"
                label="类型"
                width="55"
                align="center"
              >
              </el-table-column>
              <el-table-column
                label="单篇月曝光"
                align="center"
                prop="impression"
              >
                <template #header="scoped">
                  <span
                    @click="mySort(scoped.column.property)"
                    style="cursor: pointer"
                  >
                    <span class="iBox">
                      单篇曝光UV
                      <i
                        :class="[
                          { sortStyle: isImpressionSort === 2 },
                          'iStyle',
                          'ibottom',
                          'el-icon-caret-bottom',
                        ]"
                      ></i>
                      <i
                        :class="[
                          { sortStyle: isImpressionSort === 1 },
                          'iStyle',
                          'itop',
                          'el-icon-caret-top',
                        ]"
                      ></i>
                    </span>
                  </span>
                </template>
                <template #default="{ row }">
                  <span>
                    {{
                      row.impression === null
                        ? "N/A"
                        : sliceDecimal(row.impression)
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="月单篇互动"
                align="center"
                prop="engagement"
              >
                <template #header="scoped">
                  <span
                    @click="mySort(scoped.column.property)"
                    style="cursor: pointer"
                  >
                    <span class="iBox">
                      单篇互动
                      <i
                        :class="[
                          { sortStyle: isEngagementSort === 2 },
                          'iStyle',
                          'ibottom',
                          'el-icon-caret-bottom',
                        ]"
                      ></i>
                      <i
                        :class="[
                          { sortStyle: isEngagementSort === 1 },
                          'iStyle',
                          'itop',
                          'el-icon-caret-top',
                        ]"
                      ></i>
                    </span>
                  </span>
                </template>
                <template #default="{ row }">
                  <span>
                    {{
                      row.engagement === null
                        ? "N/A"
                        : sliceDecimal(row.engagement)
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="ER"
                align="center"
                width="100"
                prop="engagementRate"
              >
                <template #header="scoped">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="互动量/曝光数"
                    placement="top"
                  >
                    <span
                      @click="mySort(scoped.column.property)"
                      style="cursor: pointer"
                    >
                      <svg-icon
                        class-name="question"
                        class="questionStyle"
                      ></svg-icon>
                      <span class="iBox">
                        ER
                        <i
                          :class="[
                            { sortStyle: isErSort === 2 },
                            'iStyle',
                            'ibottom',
                            'el-icon-caret-bottom',
                          ]"
                        ></i>
                        <i
                          :class="[
                            { sortStyle: isErSort === 1 },
                            'iStyle',
                            'itop',
                            'el-icon-caret-top',
                          ]"
                        ></i>
                      </span>
                    </span>
                  </el-tooltip>
                </template>
                <template #default="{ row }">
                  <span>
                    {{
                      row.engagementRate === null ? "N/A" : row.engagementRate
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="cpe"
                label="CPE"
                width="100"
                align="center"
              >
                <template #header="scoped">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="单个互动成本"
                    placement="top"
                  >
                    <span
                      @click="mySort(scoped.column.property)"
                      style="cursor: pointer"
                    >
                      <svg-icon
                        class-name="question"
                        class="questionStyle"
                      ></svg-icon>
                      <span class="iBox">
                        CPE
                        <i
                          :class="[
                            { sortStyle: isCpeSort === 2 },
                            'iStyle',
                            'ibottom',
                            'el-icon-caret-bottom',
                          ]"
                        ></i>
                        <i
                          :class="[
                            { sortStyle: isCpeSort === 1 },
                            'iStyle',
                            'itop',
                            'el-icon-caret-top',
                          ]"
                        ></i>
                      </span>
                    </span>
                  </el-tooltip>
                </template>
                <template #default="{ row }">
                  <span>{{ sliceDecimal(row.cpe) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="cpi"
                label="CPM"
                align="center"
                width="110"
              >
                <template #header="scoped">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="单个UV触达成本"
                    placement="top"
                  >
                    <span
                      @click="mySort(scoped.column.property)"
                      style="cursor: pointer"
                    >
                      <svg-icon
                        class-name="question"
                        class="questionStyle"
                      ></svg-icon>
                      <span class="iBox">
                        CPUV
                        <i
                          :class="[
                            { sortStyle: isCpmSort === 2 },
                            'iStyle',
                            'ibottom',
                            'el-icon-caret-bottom',
                          ]"
                        ></i>
                        <i
                          :class="[
                            { sortStyle: isCpmSort === 1 },
                            'iStyle',
                            'itop',
                            'el-icon-caret-top',
                          ]"
                        ></i>
                      </span>
                    </span>
                  </el-tooltip>
                </template>
                <template #default="{ row }">
                  <span>{{ sliceDecimal(row.cpi) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="cpta"
                label="CPTA"
                align="center"
                width="120"
              >
                <template #header="scoped">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="单个TA触达成本"
                    placement="top"
                  >
                    <span
                      @click="mySort(scoped.column.property)"
                      style="cursor: pointer"
                    >
                      <svg-icon
                        class-name="question"
                        class="questionStyle"
                      ></svg-icon>
                      <span class="iBox">
                        CPTA
                        <i
                          :class="[
                            { sortStyle: isCptaSort === 2 },
                            'iStyle',
                            'ibottom',
                            'el-icon-caret-bottom',
                          ]"
                        ></i>
                        <i
                          :class="[
                            { sortStyle: isCptaSort === 1 },
                            'iStyle',
                            'itop',
                            'el-icon-caret-top',
                          ]"
                        ></i>
                      </span>
                    </span>
                  </el-tooltip>
                </template>
                <template #default="{ row }">
                  <span>{{ sliceDecimal(row.cpta) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="price"
                label="参考价格"
                :show-overflow-tooltip="true"
                align="center"
                width="140"
              >
                <template #header="scoped">
                  <el-tooltip class="item" effect="dark" placement="top">
                    <!-- <template #content>
                      <div>
                        <span
                          class="priceSpan green"
                          style="margin-right: 5px"
                        ></span
                        >第三方价格<br />
                        <span
                          class="priceSpan yellow"
                          style="margin-right: 5px"
                        ></span
                        >拟合价格
                      </div>
                    </template> -->
                    <template #content>
                      <span
                        class="priceSpan blue"
                        style="margin-right: 10px"
                      ></span
                      >活动价格<br />
                      <span
                        class="priceSpan pink"
                        style="margin-right: 10px"
                      ></span
                      >用户上传价格<br />
                      <span
                        class="priceSpan green"
                        style="margin-right: 10px"
                      ></span
                      >刊例价<br />
                      <span
                        class="priceSpan yellow"
                        style="margin-right: 10px"
                      ></span
                      >拟合价
                    </template>
                    <span
                      @click="mySort(scoped.column.property)"
                      style="cursor: pointer"
                    >
                      <svg-icon
                        class-name="question"
                        class="questionStyle"
                      ></svg-icon>
                      <span class="iBox">
                        参考价格
                        <i
                          :class="[
                            { sortStyle: isPriceSort === 2 },
                            'iStyle',
                            'ibottom',
                            'el-icon-caret-bottom',
                          ]"
                        ></i>
                        <i
                          :class="[
                            { sortStyle: isPriceSort === 1 },
                            'iStyle',
                            'itop',
                            'el-icon-caret-top',
                          ]"
                        ></i>
                      </span>
                    </span>
                  </el-tooltip>
                </template>
                <template #default="scope">
                  <!-- <span
                    :class="
                      scope.row.priceSource === 'crawl'
                        ? 'priceGreen'
                        : 'priceYellow'
                    "
                    >{{
                      sliceDecimal(
                        scope.row.price === null ? "--" : scope.row.price
                      )
                    }}</span
                  > -->
                  <span :class="setBorderColorStyle(scope.row.priceSource)">
                    {{
                      sliceDecimal(
                        scope.row.price === null ? "--" : scope.row.price
                      )
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="60">
                <template #default="scope">
                  <el-tooltip
                    v-if="!addMoreBtn"
                    class="item"
                    effect="dark"
                    content="删除"
                    placement="top"
                  >
                    <i
                      style="cursor: pointer"
                      @click="delCampaignKol(scope.row.ckId)"
                      class="el-icon-delete"
                    ></i>
                  </el-tooltip>
                  <i
                    v-else
                    style="cursor: not-allowed; color: #b3b0ad"
                    class="el-icon-delete"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title=""
      v-model="winnerDialog"
      @close="winnerDialogClose"
      width="90%"
    >
      <div>
        <el-radio-group v-model="winnerChosenType" size="mini">
          <el-radio-button label="all">全部</el-radio-button>
          <el-radio-button label="lowest_price"
            >总价最低供应商
          </el-radio-button>
          <el-radio-button label="lowest_comb"
            >KOL价格最低组合
          </el-radio-button>
        </el-radio-group>
        <el-table
          ref="multipleTable"
          :data="kolPriceTableData[priceDetailPlatform]"
          style="width: 100%; margin-top: 10px"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column
            prop="kolName"
            label="KOL名称"
            width="130"
            :show-overflow-tooltip="true"
            align="left"
          >
            <template #default="scope">
              <div class="nameStyle">
                <img :src="scope.row.logoUrl" alt="" />
                <span>{{ scope.row.kolName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="platform"
            label="平台"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="pKolid" label="KOL ID" align="left">
          </el-table-column>
          <el-table-column
            prop="kolCategory"
            label="类型"
            width="55"
            align="center"
          >
          </el-table-column>
          <el-table-column label="供应商" align="center">
            <template #default="scope">
              <ul>
                <li v-for="(item, i) in scope.row.quoteList" :key="i">
                  <div class="pClass">
                    <div>{{ item.agencyName }}</div>
                  </div>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            prop="coopType"
            label="合作类型"
            width="140"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template #default="scope">
              <ul class="ulOverStyle">
                <li v-for="(item, i) in scope.row.quoteList" :key="i">
                  <div class="pClass">
                    <div>{{ item.coopType }}</div>
                  </div>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            prop="rightsType"
            label="权益类型"
            align="center"
            width="200"
          >
            <template #default="scope">
              <ul>
                <li v-for="(item, i) in scope.row.quoteList" :key="i">
                  <div class="pClass">
                    <div>
                      {{ item.rightsType === "" ? "" : item.rightsType }}
                    </div>
                  </div>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            prop="contentType"
            label="内容类型"
            align="center"
            width="120"
          >
            <template #default="scope">
              <ul>
                <li v-for="(item, i) in scope.row.quoteList" :key="i">
                  <div class="pClass">
                    <div>{{ item.contentType }}</div>
                  </div>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            prop="otherRights"
            label="其他权益"
            :show-overflow-tooltip="true"
            align="center"
            width="140"
          >
            <template #default="scope">
              <ul>
                <li v-for="(item, i) in scope.row.quoteList" :key="i">
                  <div class="pClass">
                    <div>{{ item.otherRights }}</div>
                  </div>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            prop="secondPrice"
            label="报价"
            align="center"
            width="130"
          >
            <template #default="scope">
              <ul>
                <li v-for="(item, i) in scope.row.quoteList" :key="i">
                  <div class="pClass">
                    <div>¥ {{ sliceDecimal(formatMoneyNum(item.price)) }}</div>
                  </div>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
            prop="comments"
            label="备注"
            :show-overflow-tooltip="true"
            align="center"
            width="80"
          >
            <template #default="scope">
              <ul>
                <li
                  class="liStyle"
                  v-for="(item, i) in scope.row.quoteList"
                  :key="i"
                >
                  <div class="pClass">
                    <div>
                      {{ item.comments === null ? "无" : item.comments }}
                    </div>
                  </div>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column label="中标供应商" align="center" width="200">
            <template #default="scope">
              <el-select
                v-model="scope.row.winnerAgency"
                @change="chooseAgency($event, scope.row)"
                placeholder="请选择供应商"
              >
                <el-option
                  v-for="item in scope.row.priceAgencyList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div>
          <el-button @click="cancelWinner">取 消</el-button>
          <el-button type="primary" @click="winnerHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog title="警告" v-model="delDiaLog" width="30%">
      <p>确定要删除此kol？</p>
      <template #footer>
        <div>
          <el-button @click="delDiaLog = false">取 消</el-button>
          <el-button type="primary" @click="realDel">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import mainTitle from "@/components/common/mainTitle";
import showCard from "@/components/common/showCard";
import filterTag from "@/components/filterTag/index";
import { mapGetters } from "vuex";
import {
  getCampaignInfo,
  putCampaignInfo,
  getBoardData,
  getBoardKolList,
  winnerAgency,
  delCamKol,
  getBoardNoAgency,
  getKolListNoAgency,
  downloadReport,
  downLoadCampaignFile,
} from "@/api/campaignInfo";

export default {
  data() {
    return {
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      title: "活动基本信息",
      campBoardTitle: "活动策划",
      campDetailsTitle: "活动报价",
      kolPriceTitle: "KOL报价详情",
      kolDetailTitle: "KOL详情",
      // 日期选择器 数据
      shortcuts: [
        {
          text: "最近一周",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "最近一个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "最近三个月",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      brandList: [],
      brandLineList: [],
      productList: [],
      agencyList: [],
      // 人群列表
      fincrowdList: [],
      // 备份表格数据
      tempFormData: null,
      infoFormData: {
        brand: "",
        docDir: "",
        campaignName: "",
        duration: [],
        campaignDesc: "",
        cost: 0,
        agency: [],
        isEdit: false,
        campaignType: "",
        brandLine: "",
        product: [],
      },
      // borad data
      price: 2,
      chosenType: "lowest_price",
      winnerChosenType: "all",
      beforeWinnerChosenType: "all",
      crowd: "32_1",
      detailCrowd: "32_1",
      kolDetailPlatform: "all",
      noAgencyTableData: {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },
      copyNoAgencyTableData: {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },
      // kol 报价详情 数据 data
      priceDetailPlatform: "all",
      kolPriceTableData: {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },
      copyKolPriceTableData: {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },
      multipleSelection: [],
      // 搜索关键字
      searchKeyNoAgency: "",
      searchKey: "",
      // 是否显示筛选框
      isShowFilter: false,
      optionsNum: 0,
      isReset: 0,
      // 合作类型 data
      corpTypeData: null,
      // 权益类型 data
      interestTypeData: null,
      // 内容类型 data
      infoTypeData: null,
      quoteList: [],
      editOrTrue: true,
      headPrice: "第二轮报价",
      isSelectPrice: false,
      // 上传活动数据
      campaignFilePath: "",
      campaignAction: process.env.VUE_APP_API_HOST + "/campaign/file",
      limit: 1,
      // 报价轮数
      // 图标数据
      dataObj: {
        totalCost: 0,
        impression: 0,
        engagement: 0,
        engagementRate: 0,
        cpe: 0,
        cpi: 0,
        cpta: 0,
      },
      agencyData: [],
      winnerDialog: false,
      winnerBtn: false,
      addMoreBtn: false,
      exitBtn: false,
      winnerAgency: "",
      // 活动分页
      campaignKolPage: 1,
      campaignKolSize: 10,
      delId: 0,
      delDiaLog: false,
      copyKolPriceData: {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      },
      isWinner: false,
      boxLoading: true,
      detailBoxLoading: true,
      tableBtn: false,
      tableBtnNoAgency: false,
      isClickWinner: false,
      isWinnerEd: true,
      isSecondColor: true,
      isClick: false,
      iskolDetailsClick: true,
      tagshow: false,
      resetFlag: false,
      noAgencyloading: true,
      Agencyloading: true,
      flag: false,
      // 排序字段
      isImpressionSort: 0,
      isEngagementSort: 0,
      isErSort: 0,
      isCpeSort: 0,
      isCpmSort: 0,
      isCptaSort: 0,
      isPriceSort: 0,
      // 限制数量
      limitNum: 0,
    };
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(["crowdList", "token", "sites"]),
  },
  mounted() {
    this.initCampaignInfo();
    this.initBoardDetailData();
    this.initNoAgencyKolList();
    this.initCrowdList();
    this.formatDropDown();

    if (this.$storage.getItem("quoteFinished") !== 0) {
      this.winnerBtn = true;
      // this.addMoreBtn = true;
      // this.exitBtn = true;
      this.isWinnerEd = false;
    }
  },
  methods: {
    setFormDuration(val) {
      if (typeof val === "string") {
        val = val.split(",");
        return `${val[0]} 至 ${val[1]}`;
      } else {
        return `${val[0]} 至 ${val[1]}`;
      }
    },
    async initCampaignInfo() {
      let res = await getCampaignInfo({
        campaignId: this.id,
      });
      this.infoFormData = this.formatData(res.data);
      this.campaignFilePath = this.infoFormData.docDir;
      this.infoFormData.cost = this.infoFormData.cost / 100;
      this.tempFormData = JSON.parse(JSON.stringify(this.infoFormData));
      this.brandList = this.$storage.getItem("brandList");
      this.brandLineList = this.$storage.getItem("brandLineList");
      this.productList = this.$storage.getItem("productList");
      this.agencyList = this.$storage.getItem("agencyList");
      this.$storage.setItem("checkActiveName", this.infoFormData.campaignName);
    },
    setBorderColorStyle(priceSource) {
      switch (priceSource) {
        case "pred":
          return "yellowText";
        case "crawl":
          return "greenText";
        case "campaign":
          return "blueText";
        case "custom":
          return "pinkText";
        default:
          return "noBorder";
      }
    },
    formatData(data) {
      let obj = {};
      for (let key in data) {
        if (key === "duration") {
          obj[key] = [data[key].split(",")[0], data[key].split(",")[1]];
          continue;
        } else if (key === "agency") {
          obj[key] = data[key] === "" ? [] : data[key].split(",");
          continue;
        } else if (key === "product") {
          obj[key] =
            data[key] === "" || data[key] === null ? [] : data[key].split(",");
          continue;
        }
        obj[key] = data[key];
      }
      obj.isEdit = false;
      return obj;
    },

    async initBoardDetailData() {
      this.detailBoxLoading = true;
      let res = await getBoardNoAgency({
        campaignId: this.id,
        ta: this.detailCrowd,
        platform: this.kolDetailPlatform,
      });
      if (res.code === 0) {
        this.dataObj = res.data;
      } else {
        this.$message.error("获取数据失败，请联系管理员！");
      }
      this.detailBoxLoading = false;
    },
    async initNoAgencyKolList() {
      this.noAgencyloading = true;
      let { code, data } = await getKolListNoAgency({
        campaignId: this.id,
        ta: this.detailCrowd,
      });
      if (code === 0) {
        this.buildObject(data);
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
      this.noAgencyloading = false;
    },
    buildObject(data) {
      this.noAgencyTableData = {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      };
      data.forEach((v) => {
        switch (v.platform) {
          case "wechat":
            v.platform = "微信";
            this.noAgencyTableData["wechat"].push(v);
            break;
          case "weibo":
            v.platform = "微博";
            this.noAgencyTableData["weibo"].push(v);
            break;
          case "douyin":
            v.platform = "抖音";
            this.noAgencyTableData["douyin"].push(v);
            break;
          case "xiaohongshu":
            v.platform = "小红书";
            this.noAgencyTableData["xiaohongshu"].push(v);
            break;
          case "bilibili":
            v.platform = "哔哩哔哩";
            this.noAgencyTableData["bilibili"].push(v);
            break;
          case "kuaishou":
            v.platform = "快手";
            this.noAgencyTableData["kuaishou"].push(v);
            break;
        }
        this.noAgencyTableData["all"].push(v);
        this.copyNoAgencyTableData = Object.assign({}, this.noAgencyTableData);
      });
      this.$storage.setItem(
        "campaignKolNum",
        this.noAgencyTableData.all.length
      );
    },
    initCrowdList() {
      this.crowdList.forEach((v) => {
        this.fincrowdList.push({
          value: v.key,
          label: v.name,
          id: v.key,
        });
      });
    },
    formatDropDown() {
      this.corpTypeData = this.$storage.getItem("coopList");
      this.interestTypeData = this.$storage.getItem("rightsList");
      this.infoTypeData = this.$storage.getItem("contentList");
    },

    resetOrder() {
      this.isImpressionSort = 0;
      this.isEngagementSort = 0;
      this.isErSort = 0;
      this.isCpeSort = 0;
      this.isCpmSort = 0;
      this.isCptaSort = 0;
      this.isPriceSort = 0;
    },
    intoCampaignReport() {
      const query = {
        id: this.btoa(this.id),
      };
      this.$router.push({ name: "CampaignReport", query });
    },
    downloadCampaignExcelNoAgency() {
      this.tableBtnNoAgency = true;
      const params = {
        campaignId: this.id,
        ta: this.detailCrowd,
      };
      downloadReport(params, `${this.infoFormData.campaignName}.xlsx`)
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载",
          });
          this.tableBtnNoAgency = false;
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
    orderReSet(type) {
      let arr = [
        "isImpressionSort",
        "isEngagementSort",
        "isErSort",
        "isCpmSort",
        "isCptaSort",
        "isPriceSort",
        "isCpeSort",
      ];
      arr.forEach((v) => {
        if (v !== type) {
          this[v] = 0;
        }
      });
    },
    setStatus(filed) {
      this.orderReSet(filed);
      this[filed] = this[filed] === 0 ? 1 : this[filed] === 1 ? 2 : 1;
    },
    mySort(filed) {
      switch (filed) {
        case "impression":
          this.setStatus("isImpressionSort");
          break;
        case "engagement":
          this.setStatus("isEngagementSort");
          break;
        case "engagementRate":
          this.setStatus("isErSort");
          break;
        case "cpe":
          this.setStatus("isCpeSort");
          break;
        case "cpi":
          this.setStatus("isCpmSort");
          break;
        case "cpta":
          this.setStatus("isCptaSort");
          break;
        case "price":
          this.setStatus("isPriceSort");
          break;
      }
      this.flag = !this.flag;
      let tempArr = [];
      let tempArrNoNull = [];
      this.noAgencyTableData[this.kolDetailPlatform].forEach((v, i) => {
        if (v[filed] === null) {
          tempArr.push(this.noAgencyTableData[this.kolDetailPlatform][i]);
        } else {
          tempArrNoNull.push(this.noAgencyTableData[this.kolDetailPlatform][i]);
        }
      });
      tempArrNoNull.sort((a, b) => {
        if (filed === "engagementRate") {
          let val1 = +a.engagementRate.substr(0, a.engagementRate.length - 1);
          let val2 = +b.engagementRate.substr(0, b.engagementRate.length - 1);
          if (this.flag) {
            return +val1 - +val2;
          } else {
            return +val2 - +val1;
          }
        } else {
          if (this.flag) {
            return +a[filed] - +b[filed];
          } else {
            return +b[filed] - +a[filed];
          }
        }
      });
      tempArrNoNull.push(...tempArr);
      this.noAgencyTableData[this.kolDetailPlatform] = tempArrNoNull;
    },
    newSearch({ filter }) {
      // 没有筛选条件
      if (JSON.stringify(filter) === "{}") {
        this.noAgencyTableData[this.kolDetailPlatform] =
          this.copyNoAgencyTableData[this.kolDetailPlatform];
        return;
      }
      // 初始化数组，每次筛选前恢复为原来的数组
      this.noAgencyTableData[this.kolDetailPlatform] =
        this.copyNoAgencyTableData[this.kolDetailPlatform];
      let arr = [];
      let arr1 = [];
      let arr2 = [];
      let arr3 = [];
      let arr4 = [];
      let arr5 = [];
      if (filter.engagementRateAbove && filter.engagementRateBelow) {
        arr1 = this.filterFunc(
          ["engagementRateAbove", "engagementRateBelow"],
          "engagementRate",
          filter
        );
        arr.push(arr1);
      }
      if (filter.cpmAbove || filter.cpmBelow) {
        arr2 = this.filterFunc(["cpmAbove", "cpmBelow"], "cpi", filter);
        arr.push(arr2);
      }
      if (filter.cpeAbove && filter.cpeBelow) {
        arr3 = this.filterFunc(["cpeAbove", "cpeBelow"], "cpe", filter);
        arr.push(arr3);
      }
      if (filter.cptaAbove && filter.cptaBelow) {
        arr4 = this.filterFunc(["cptaAbove", "cptaBelow"], "cpta", filter);
        arr.push(arr4);
      }
      if (filter.priceAbove && filter.priceBelow) {
        arr5 = this.filterFunc(
          ["averageCostAbove", "averageCostBelow"],
          "price",
          filter
        );
        arr.push(arr5);
      }
      // 如果有数组为空，则没有满足条件的项
      if (arr.length === 1) {
        this.noAgencyTableData[this.kolDetailPlatform] = arr[0];
      } else if (arr.some((v) => v.length === 0)) {
        this.noAgencyTableData[this.kolDetailPlatform] = [];
      } else {
        let minArr = [];
        let defaultArr = [];
        arr.forEach((v) => {
          minArr = v;
          if (v.length < minArr.length) {
            minArr = v;
          }
        });
        minArr.forEach((v) => {
          let state = [];
          arr.forEach((k) => {
            k.forEach((l) => {
              if (l.pKolid === v.pKolid) {
                state.push(true);
              }
            });
          });
          if (state.length === arr.length) {
            const status = state.every((i) => i === true);
            if (status) {
              if (defaultArr.length === 0) {
                defaultArr.push(v);
              } else {
                defaultArr.forEach((m) => {
                  if (m.pKolid !== v.pKolid) {
                    defaultArr.push(v);
                  }
                });
              }
            }
          }
        });
        this.noAgencyTableData[this.kolDetailPlatform] = defaultArr;
      }
    },
    filterFunc(condition, value, filter) {
      return this.noAgencyTableData[this.kolDetailPlatform].filter((v) => {
        if (value === "engagementRate") {
          if (v[value] === null) {
            return false;
          } else {
            return (
              +filter[condition[0]] * 100 < +v[value].replace(/%/g, "") &&
              +v[value].replace(/%/g, "") < +filter[condition[1]] * 100
            );
          }
        } else {
          if (v[value] === null) {
            return false;
          } else {
            return (
              +filter[condition[0]] < +v[value] &&
              +v[value] < +filter[condition[1]]
            );
          }
        }
      });
    },

    tabClick(tab) {
      if (tab.paneName === "1") {
        this.isClick = true;
        this.iskolDetailsClick = false;
        this.dataObj = {
          totalCost: 0,
          impression: 0,
          engagement: 0,
          engagementRate: 0,
          cover: 0,
          kpi: 0,
          cpe: 0,
          cpi: 0,
          cpta: 0,
        };
        this.agencyData = [];
        this.initBoardData();
        // this.initBoardKolList();
      } else {
        this.tagshow = false;
        this.dataObj = {
          totalCost: 0,
          impression: 0,
          engagement: 0,
          engagementRate: 0,
          cover: 0,
          kpi: 0,
          cpe: 0,
          cpi: 0,
          cpta: 0,
        };
        this.initBoardDetailData();
        this.initNoAgencyKolList();
        this.isClick = false;
        this.iskolDetailsClick = true;
      }
    },
    async initBoardData() {
      this.boxLoading = true;
      let res = await getBoardData({
        campaignId: this.id,
        round: this.price,
        type: this.isWinnerEd ? this.chosenType : "quote_finished",
        ta: this.crowd,
        platform: this.priceDetailPlatform,
      });
      if (res.code === 0) {
        Object.keys(this.dataObj).forEach((key) => {
          this.dataObj[key] = res.data[key];
        });
      } else {
        this.$message.error("获取数据失败，请联系管理员！");
        this.boxLoading = false;
      }
      this.agencyData = res.data.agencyData || [];
      this.boxLoading = false;
    },
    async initBoardKolList() {
      this.Agencyloading = true;
      let res = await getBoardKolList({
        campaignId: this.id,
        quoteFinished: this.$storage.getItem("quoteFinished"),
      });
      if (res.code === 0) {
        if (res.data.kolList.length === 0) {
          this.winnerBtn = true;
        }
        res.data.kolList.forEach((v) => {
          v.isEdit = false;
        });
        let tempRes = res.data.kolList;
        this.setPriceAgencyList(res.data.kolList);
        this.isWinner = this.kolIsWinner(res.data.kolList);
        this.setLowerPrice(tempRes);
        this.setComb(tempRes);
        this.filterSite(res.data.kolList);
        this.Agencyloading = false;
      } else {
        this.$message({
          type: "error",
          message: "数据请求失败，请联系管理员!",
        });
        this.Agencyloading = false;
      }
    },
    // 构建 报价 agency 列表
    setPriceAgencyList(data) {
      data.forEach((v) => {
        v.priceAgencyList = [];
        v.quoteList.forEach((k) => {
          v.priceAgencyList.push({
            value: k.agencyName,
            id: this.getPriceAgencyId(k.agencyName),
            price: k.price,
          });
        });
      });
    },
    getPriceAgencyId(data) {
      let id = "";
      let agencyList = this.$storage.getItem("agencyList");
      agencyList.forEach((v) => {
        if (v.value === data) {
          id = v.id;
        }
      });
      return id;
    },
    kolIsWinner(data) {
      return data.some((v) => {
        return v.priceAgencyList.length === 0;
      });
    },
    setLowerPrice(data) {
      let arr = JSON.parse(JSON.stringify(data));
      arr.forEach((v) => {
        if (v.quoteList.length !== 0) {
          v.quoteList.sort((a, b) => {
            return a.price - 0 - (b.price - 0);
          });
          v.lowerPriceAgency = v.quoteList[0].agencyName;
        }
      });
    },
    setComb(data) {
      let tempData = JSON.parse(JSON.stringify(data));
      let allAgency = this.$storage.getItem("agencyList");
      allAgency.forEach((v) => {
        let tempSum = 0;
        tempData.forEach((k) => {
          k.quoteList.forEach((l) => {
            if (v.value === l.agencyName) {
              tempSum += l.price - 0;
            }
          });
        });
        v.priceSum = tempSum;
      });
      allAgency.sort((a, b) => {
        return a.priceSum - b.priceSum;
      });
      let arr = allAgency.filter((v) => {
        return v.priceSum !== 0;
      });
      if (arr.length !== 0) {
        data.forEach((v) => {
          v.minPriceAgency = arr[0].value;
        });
      }
    },
    filterSite(data) {
      this.kolPriceTableData = {
        all: [],
        wechat: [],
        weibo: [],
        douyin: [],
        xiaohongshu: [],
        bilibili: [],
        kuaishou: [],
      };
      data.forEach((v) => {
        switch (v.platform) {
          case "wechat":
            v.platform = "微信";
            this.kolPriceTableData["wechat"].push(v);
            break;
          case "weibo":
            v.platform = "微博";
            this.kolPriceTableData["weibo"].push(v);
            break;
          case "douyin":
            v.platform = "抖音";
            this.kolPriceTableData["douyin"].push(v);
            break;
          case "xiaohongshu":
            v.platform = "小红书";
            this.kolPriceTableData["xiaohongshu"].push(v);
            break;
          case "bilibili":
            v.platform = "哔哩哔哩";
            this.kolPriceTableData["bilibili"].push(v);
            break;
          case "kuaishou":
            v.platform = "快手";
            this.kolPriceTableData["kuaishou"].push(v);
            break;
        }
        this.kolPriceTableData["all"].push(v);
        this.copyKolPriceTableData = JSON.parse(
          JSON.stringify(this.kolPriceTableData)
        );
      });
    },

    editInfo() {
      this.infoFormData.isEdit = true;
      this.infoFormData.duration =
        typeof this.infoFormData.duration === "string"
          ? this.infoFormData.duration.split(",")
          : this.infoFormData.duration;
    },
    cancelEdit() {
      this.infoFormData = JSON.parse(JSON.stringify(this.tempFormData));
      this.infoFormData.agency = this.cancelAgencyFormat(
        this.infoFormData.agency
      );
      this.infoFormData.cost = (this.infoFormData.cost - 0) / 100;
    },
    cancelAgencyFormat(data) {
      if (!Array.isArray(data)) {
        let temp = [];
        JSON.parse(data).forEach((v) => {
          temp.push(v.name);
        });
        return temp;
      } else {
        return data;
      }
    },

    async saveInfoForm() {
      this.infoFormData.isEdit = false;
      // 当修改上传了文件时修改路径
      if (this.campaignFilePath !== "") {
        this.infoFormData.docDir = this.campaignFilePath;
      }
      this.tempFormData = JSON.parse(JSON.stringify(this.infoFormData));
      this.tempFormData.agency = this.commitAgencyFormat(
        this.tempFormData.agency
      );
      this.tempFormData.duration = this.tempFormData.duration.join(",");
      this.tempFormData.cost = (this.tempFormData.cost - 0) * 100;
      // 当修改上传了文件时修改路径
      if (this.campaignFilePath !== "") {
        this.tempFormData.docDir = this.campaignFilePath;
      }
      this.tempFormData.product = this.tempFormData.product.join(",");

      let res = await putCampaignInfo(this.tempFormData);
      if (res.code === 0) {
        this.$message({
          type: "success",
          message: "修改成功!",
        });
      }
    },
    commitAgencyFormat(data) {
      let tempList = [];
      this.agencyList.forEach((k) => {
        data.forEach((v) => {
          if (k.value === v) {
            tempList.push({
              id: k.id,
              name: k.value,
            });
          }
        });
      });
      return JSON.stringify(tempList);
    },

    downLoadCampaignFile() {
      if (this.infoFormData.docDir === "") {
        this.$message({
          type: "info",
          message: "未上传文档",
        });
      } else {
        let fileDic = this.infoFormData.docDir
          .replace(/\\/g, "/")
          .split("/")
          .pop();
        let loadFileName = fileDic.split("$$")[0] + "." + fileDic.split(".")[1];
        downLoadCampaignFile(loadFileName)
          .then(() => {
            this.$message({
              type: "success",
              message: "文件正在下载，请耐心等待",
            });
          })
          .catch(() => {
            this.$message.error("文件下载失败,请联系管理员!");
          });
      }
    },
    filterHandle() {
      this.tagshow = !this.tagshow;
    },
    // 上传 文档 处理方法
    fileBeforeUpload(file) {
      const sizeLimit = 1024 * 1024 * 30;
      if (file.size > sizeLimit) {
        this.$message({
          message: "文件不能大于30M",
          type: "warning",
        });
        return false;
      } else if (file.size < 0) {
        this.$message({
          message: "文件不能为空!",
        });
      } else {
        return true;
      }
    },
    campaignFileOnSuccess(res) {
      if (res.code === 0) {
        this.campaignFilePath = res.data;
      } else if (res.code === 202) {
        this.$message.error("文件不存在");
      }
    },
    campaignFileOnError() {
      this.$refs.campaignUpload.clearFiles();
      this.$message.error("上传文件失败!");
    },
    fileListRemove(file, fileList) {
      if (fileList.length === 0) {
        // 删除了上传文
        this.campaignFilePath = "";
      }
    },

    additionalKol() {
      // { name: campaignName, to: returnCampaignPath },
      this.$storage.setItem("campaignName", this.infoFormData.campaignName);
      this.$storage.setItem(
        "returnCampaignPath",
        `/campaignInfo?id=${this.btoa(this.id)}`
      );
      this.$router.push({
        name: "AdditionalKol",
        query: { id: this.btoa(this.id) },
      });
    },
    async downloadCampaignForm() {
      this.tableBtn = true;
      const params = {
        campaignId: this.id,
        ta: this.crowd,
      };
      downloadReport(params, `${this.infoFormData.campaignName}.xlsx`)
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载",
          });
          this.tableBtn = false;
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
    winner() {
      this.isClickWinner = true;
      if (this.isWinner) {
        this.$notify({
          title: "提示",
          message: "请完成所有kol报价！",
        });
      } else {
        this.copyKolPriceData = JSON.parse(
          JSON.stringify(this.kolPriceTableData)
        );
        this.winnerDialog = true;
      }
    },
    async winnerHandle() {
      let bool = this.kolPriceTableData["all"].every((v) => {
        return v.winnerAgency;
      });
      if (bool) {
        let res = await winnerAgency({
          campaignId: this.id,
          winners: this.getAgencyId(),
        });
        if (res.code === 0) {
          this.$message({
            type: "success",
            message: "完成中标",
          });
          this.isWinnerEd = false;
          this.$storage.setItem("quoteFinished", 1);
          // await this.initBoardKolList();
          this.winnerDialog = false;
          this.isClickWinner = false;
          this.winnerBtn = true;
          // this.addMoreBtn = true;
          // this.exitBtn = true;
        }
      } else {
        this.$message({
          type: "warning",
          message: "请选择供应商",
        });
      }
    },
    cancelWinner() {
      this.winnerDialog = false;
      this.isClickWinner = false;
      this.kolPriceTableData = this.copyKolPriceData;
    },
    winnerDialogClose() {
      this.isClickWinner = false;
    },

    // 需要修改
    getAgencyId() {
      let tempArr = [];
      let agencyList = this.$storage.getItem("agencyList");
      this.kolPriceTableData[this.priceDetailPlatform].forEach((v) => {
        if (v.winnerAgency) {
          agencyList.forEach((k) => {
            if (k.value === v.winnerAgency) {
              tempArr.push({
                id: v.ckId,
                agencyId: k.id,
              });
            }
          });
        }
      });
      return tempArr;
    },
    campaignSearchNoAgency() {
      if (this.searchKeyNoAgency === "") {
        this.noAgencyTableData[this.kolDetailPlatform] =
          this.copyNoAgencyTableData[this.kolDetailPlatform];
        return;
      }
      let byName = this.copyNoAgencyTableData[this.kolDetailPlatform].filter(
        (v) => {
          return v.kolName.includes(this.searchKeyNoAgency);
        }
      );
      let byId = this.copyNoAgencyTableData[this.kolDetailPlatform].filter(
        (k) => {
          return k.pKolid.includes(this.searchKeyNoAgency);
        }
      );
      let res = new Map();
      let tempArr = [...byName, ...byId];
      this.noAgencyTableData[this.kolDetailPlatform] = tempArr.filter(
        (a) => !res.has(a.pKolid) && res.set(a.pKolid, 1)
      );
    },
    campaignSearch() {
      if (this.searchKey === "") {
        this.kolPriceTableData[this.priceDetailPlatform] =
          this.copyKolPriceTableData[this.priceDetailPlatform];
        return;
      }
      let byName = this.kolPriceTableData[this.priceDetailPlatform].filter(
        (v) => {
          return v.kolName.includes(this.searchKey);
        }
      );

      let byId = this.kolPriceTableData[this.priceDetailPlatform].filter(
        (k) => {
          return k.pKolid.includes(this.searchKey);
        }
      );
      this.kolPriceTableData[this.priceDetailPlatform] = [...byName, ...byId];
    },
    delCampaignKol(id) {
      this.delDiaLog = true;
      this.delId = id;
    },
    async realDel() {
      this.delDiaLog = false;
      let res = await delCamKol({
        ckId: this.delId,
      });
      if (res.code === 0) {
        this.$message({
          message: "删除KOL成功!",
          type: "success",
        });
        await this.initNoAgencyKolList();
        // await this.initBoardKolList();
      }
    },

    chooseAgency(val, data) {
      let temp = JSON.parse(
        JSON.stringify(
          this.copyKolPriceData[this.priceDetailPlatform].find(
            (v) => v.ckId === data.ckId
          )
        )
      );
      data.quoteList = temp.quoteList.splice(
        temp.quoteList.findIndex((k) => {
          return k.agencyName === val;
        }),
        1
      );
    },
    filterType(type) {
      if (this.copyKolPriceData["all"].length === 0) {
        this.copyKolPriceData = JSON.parse(
          JSON.stringify(this.kolPriceTableData)
        );
      }
      if (type === "all") {
        this.kolPriceTableData = JSON.parse(
          JSON.stringify(this.copyKolPriceData)
        );
      } else if (type === "lowest_price") {
        this.kolPriceTableData = JSON.parse(
          JSON.stringify(this.copyKolPriceData)
        );
        if (!this.isClickWinner) {
          this.kolPriceTableData[this.priceDetailPlatform] =
            this.kolPriceTableData[this.priceDetailPlatform].filter((v) => {
              return JSON.stringify(v.priceAgencyList).includes(
                v.minPriceAgency
              );
            });
          this.kolPriceTableData[this.priceDetailPlatform].forEach((v) => {
            v.winnerAgency = v.minPriceAgency;
            this.chooseAgency(v.minPriceAgency, v);
          });
        } else {
          this.kolPriceTableData[this.priceDetailPlatform].forEach((v) => {
            if (JSON.stringify(v.priceAgencyList).includes(v.minPriceAgency)) {
              v.winnerAgency = v.minPriceAgency;
              this.chooseAgency(v.minPriceAgency, v);
            } else {
              v.winnerAgency = "";
            }
          });
        }
        // 总价最低供应商
      } else {
        this.kolPriceTableData = JSON.parse(
          JSON.stringify(this.copyKolPriceData)
        );
        this.kolPriceTableData[this.priceDetailPlatform].forEach((v) => {
          v.winnerAgency = v.lowerPriceAgency;
          this.chooseAgency(v.lowerPriceAgency, v);
        });
      }
    },
  },
  watch: {
    chosenType() {
      this.initBoardData();
    },
    kolDetailPlatform() {
      this.initBoardDetailData();
      this.campaignSearchNoAgency();
    },
    priceDetailPlatform() {
      this.initBoardData();
    },
    detailCrowd() {
      this.initBoardDetailData();
      this.resetOrder();
      this.initNoAgencyKolList();
    },
    crowd() {
      this.initBoardData();
    },
    winnerChosenType(newVal) {
      this.filterType(newVal);
    },
    beforeWinnerChosenType(newVal) {
      this.filterType(newVal);
    },
  },
  components: {
    mainTitle,
    showCard,
    filterTag,
  },
};
</script>

<style lang="scss">
.camInfo {
  background-color: #fff;

  .el-form-item__label {
    font-size: 16px;
    font-weight: 700;
  }

  .el-date-editor.el-range-editor.el-input__inner.el-date-editor--daterange {
    width: 292px;
  }

  .el-upload-list.el-upload-list--text {
    position: relative;
    top: -14px;
    left: -6px;
    width: 300px;

    li {
      margin: 0;
    }
  }

  .el-input__inner {
    height: 35px;
    line-height: 35px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .btnList {
    .el-radio-button__inner {
      padding: 8px 20px;
    }
  }

  .cell.el-tooltip {
    font-size: 12px;
  }

  .el-tabs__content {
    overflow: visible;
  }
  .btnGroup .el-select__caret.el-input__icon {
    position: relative;
    top: 5px;
  }
  .el-date-editor .el-range-separator {
    width: 8% !important;
    line-height: 30px;
  }
}
</style>

<style lang="scss" scoped>
.mainInfo {
  margin-top: 20px;
  padding: 0 20px;

  .infoForm {
    display: flex;

    .wrap {
      flex: 1;
    }

    .right {
      .imgBox {
        width: 25px;
        height: 25px;

        .svgBox {
          width: 100%;
          height: 100%;
          cursor: pointer;
          position: relative;
          top: 5px;
          fill: skyblue;
        }

        .upload {
          width: 23px;
          height: 23px;
        }
      }
    }
  }
}

.campaignReportStyle {
  background-color: #67c23a !important;
  color: #fff !important;
  position: absolute;
  z-index: 999;
  top: -35px;
  right: 15px;
  border-radius: 5px;

  .reportClass {
    fill: #fff;
    margin-right: 5px;
    margin-top: 1px;
  }
}

.priceSpan {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
}

.green {
  background-color: #67c23a;
}
.blue {
  background-color: #53bad2;
}

.pink {
  background-color: #f37092;
}

.yellow {
  background-color: #e6a23c;
}

.pinkText {
  color: #f37092;
}
.blueText {
  color: #51a6f0;
}

.greenText {
  color: #67c23a;
}

.yellowText {
  color: #e6a23c;
}

.priceGreen {
  color: #67c23a;
}

.priceYellow {
  color: #e6a23c;
}

.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
}

.campBoard {
  margin-bottom: 20px;
}

.campBrandFormBox {
  margin-top: 20px;
}

.littleBox {
  display: flex;
  justify-content: space-around;

  .box {
    width: 175px;
    text-align: center;
  }
}

.bigBox {
  margin-top: 20px;
  overflow: hidden;
  padding-bottom: 9px;

  .svgClass {
    font-size: 50px;
    fill: #fff;
  }

  .bBox {
    width: 415px;
    height: 100px;
    float: left;
    background: #409eff;
    border-radius: 5px;
    padding: 10px 10px 8px;
    color: #fff;
    margin-left: 10px;
    box-shadow: 1px 5px 4px #ccc;

    .left {
      float: left;
      width: 60px;
      position: relative;
      top: 0px;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .right {
      width: 330px;
      float: right;
    }

    .titleP {
      text-align: left;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .innerDiv {
      .innerDivBox {
        display: flex;
        justify-content: space-between;

        .boxWrap {
          width: 65%;
        }

        .bigWrap {
          width: 95%;
        }
      }
    }
  }
}

.kolPriceDetailHead {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // .choose {
  //   margin-left: 50px;
  //   flex: 1;
  //   font-size: 16px;
  //   cursor: pointer;
  // }

  .btnGroup {
    display: flex;
    align-items: center;
    float: right;
  }
}

.pClass {
  font-size: 12px;
  min-height: 23px;
}

.nameStyle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;

  img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.ulOverStyle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.zhongbiaoStyle,
.downloadStyle {
  fill: #fff;
  margin-right: 5px;
}

.kolDetails,
.priceDetails {
  margin-right: 5px;

  &:hover {
    fill: #409eff;
  }
}

.isClickColor {
  fill: #409eff;
}

.questionStyle {
  fill: #60738c;
  cursor: pointer;
  position: relative;
  top: 1px;
}

.iBox {
  position: relative;
}

.iStyle {
  position: absolute;
  color: #c0c4cc;
  right: -16px;
}

.ibottom {
  top: 6px;
}

.itop {
  top: 0px;
}

.sortStyle {
  color: #409eff;
}
</style>
