import request, { applicationJson, downloadFile } from "@/utils/axios";

export function getCampaignInfo(params) {
  return request.get("/campaign/info", params);
}

export function putCampaignInfo(params) {
  return applicationJson.put("/campaign/info", params);
}

export function getBoardData(params) {
  return request.get("/campaign/agency/board", params);
}

export function getBoardKolList(params) {
  return request.get("/campaign/kol/agency", params);
}

export function winnerAgency(params) {
  return applicationJson.post("/campaign/quote/winner", params);
}

export function delCamKol(params) {
  return request.delete("/campaign/kol", params);
}

export function getBoardNoAgency(params) {
  return request.get("/campaign/kol/board", params);
}

export function getKolListNoAgency(params) {
  return request.get("/campaign/v2/kol/price", params);
}

// campaign/report
export function downloadReport(params, fileName) {
  return downloadFile("/campaign/report", params, fileName);
}

export function downLoadCampaignFile(fileName) {
  return downloadFile("/campaign/file", null, fileName);
}
